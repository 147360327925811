import { mapActions, mapState } from 'vuex'

export default {
    name: "InstitutionIndex",
    data: () => ({
        collections: [],
        params: {},
        message: '',
        error: false,
        loading: false,
        meta: {},
        itemDelete: {},
        model: {},
        occupation_areas: [],
        course_levels: [],
        categories: [],
        show: false,
        showForm: false,
        text: '',
        deleting: false,
        headers: [
            { text: 'Codigo', value: 'code'},
            { text: 'Nome', value: 'name'},
            { text: 'Sigla', value: 'initials'},
            { text: 'Status', value: 'active', type: 'boolean'},
            { text: 'Ações', value: 'actions', sortable: false}
        ]
    }),
    methods: {
        ...mapActions('institution', ['ActionFindInstitutions', 'ActionDeleteInstitution']),
        getData() {
            this.showForm = false;
            this.error = false
            this.message = ''
            this.loading = true
            const params = this.params
            
            Object.assign(params, {
                institution_id: this.acesso.instituicao.id,
                with: 'brand,brand.educationalGroup,teacherExtractSetting,integrationParameters',
                showAllCities: true
            })
            
            this.ActionFindInstitutions(params)
                .then((res) => {
                    this.collections = res.data
                    this.meta = res.meta
                })
                .finally(() => {
                    this.loading = false
                })
        },
        deleteItem()
        {
            this.deleting = true
            this.message = ''

            this.ActionDeleteInstitution({id: this.itemDelete.id})
                .then((res) => {
                    this.message = res.message
                    this.show = false
                    this.getData()
                })
                .catch((error) =>{
                    this.message = error.message
                    this.error = true
                })
                .finally(() => {
                    this.deleting = false
                })
        }
    },
    watch: {
        params(params_new, params_old){
            if(params_new != params_old) {
                this.getData()
            }
        }, //Função para tratar quando um item for exibido para deletar
        itemDelete(item_new, item_old){
            if(item_new !== item_old && Object.keys(item_new).length > 0)
            {
                this.show = true
                this.text = "Deseja excluir " + item_new.name + "?"
            } else {
                this.show = false
                this.text = ''
            }
        },
        show(show_new, show_old) {
            
            if(show_new != show_old && !show_new) {
                this.itemDelete = {};
            }
        },
        model(item_new, item_old){
            if(item_new !== item_old && this.model.id)
            {
                this.titleForm = 'Editar curso'
                this.showForm = true
            } else if(Object.keys(this.model).length === 0){
                this.titleForm = 'Cadastrar curso'
                this.showForm = false
            }           
        },
        showForm(show_new, show_old) {
            if(show_new != show_old && !show_new) {
                this.model = {};
            }
        },
    },
    computed: {
        ...mapState('auth', ['acesso'])
    },
}